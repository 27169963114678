<template>
	<footer id="footer">
		<router-link to="/"><img :src="imageForRoute('/')" class="img-responsive center-block"></router-link>
		<router-link to="/gallery"><img :src="imageForRoute('/gallery')" class="img-responsive center-block"></router-link>
		<router-link to="/content"><img :src="imageForRoute('/content')" class="img-responsive center-block"></router-link>
		<router-link to="/news"><img :src="imageForRoute('/news')" class="img-responsive center-block"></router-link>
		<router-link to="/contacts"><img :src="imageForRoute('/contacts')" class="img-responsive center-block"></router-link>
	</footer>
</template>

<script>
let imagesForRoute = {
	"Home": {
		"on": require("../assets/button_home_on.png"),
		"off": require("../assets/button_home_off.png"),
	},
	"Gallery": {
		"on": require("../assets/button_gallery_on.png"),
		"off": require("../assets/button_gallery_off.png"),
	},
	"Content": {
		"on": require("../assets/button_service_on.png"),
		"off": require("../assets/button_service_off.png"),
	},
	"News": {
		"on": require("../assets/button_news_on.png"),
		"off": require("../assets/button_news_off.png"),
	},
	"Contacts": {
		"on": require("../assets/button_contact_on.png"),
		"off": require("../assets/button_contact_off.png"),
	},
}

export default {
	name: "Footer",
	computed: {
		currentRouteName () {
			return this.$route.name
		},
	},
	methods: {
		imageForRoute (route) {
			if (route == "/")         { return this.currentRouteName == "Home"     ? imagesForRoute.Home.on     : imagesForRoute.Home.off     }
			if (route == "/gallery")  { return this.currentRouteName == "Gallery"  ? imagesForRoute.Gallery.on  : imagesForRoute.Gallery.off  }
			if (route == "/content")  { return this.currentRouteName == "Content"  ? imagesForRoute.Content.on  : imagesForRoute.Content.off  }
			if (route == "/news")     { return this.currentRouteName == "News"     ? imagesForRoute.News.on     : imagesForRoute.News.off     }
			if (route == "/contacts") { return this.currentRouteName == "Contacts" ? imagesForRoute.Contacts.on : imagesForRoute.Contacts.off }
		}
	}
}
</script>

<style scoped>
footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 4px;
	text-align: center;
	border-top: solid 2px #27CBA5;
	background-color: #FFFFFF;
}
footer img {
	max-height: 60px;
}
</style>