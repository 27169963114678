import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/gallery',
		name: 'Gallery',
		component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
	},
	{
		path: '/content',
		name: 'Content',
		component: () => import(/* webpackChunkName: "content" */ '../views/Content.vue')
	},
	{
		path: '/news',
		name: 'News',
		component: () => import(/* webpackChunkName: "news" */ '../views/News.vue')
	},
	{
		path: '/contacts',
		name: 'Contacts',
		component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
	},
]

const router = new VueRouter({
	routes
})

export default router
