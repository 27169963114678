<template>
	<div id="view-home">
	</div>
</template>

<script>
export default {
	name: 'Home',
}
</script>

<style>
#view-home {
	background-color: #765E36;
	height: 100%;
	background-image: url("../assets/Essenza Cofani - Home.png");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}
</style>