<template>
	<header id="header">
		<img src="../assets/Essenza Cofani - Logo.png" alt="Essenza Cofani" class="img-responsive center-block">
	</header>
</template>

<script>
export default {
	name: "Header",
}
</script>

<style scoped>
header {
	border-bottom: solid 2px #27CBA5;
	background-color: #765E36;
	padding: 8px;
}
header img {
	max-height: 50px;
}
</style>