<template>
	<div id="app" :layout="layout">
		<template v-if="layout == 'content-and-footer'">
			<content>
				<router-view></router-view>
			</content>
			<Footer></Footer>
		</template>
		<template v-if="layout == 'header-content-footer'">
			<Header></Header>
			<content>
				<router-view></router-view>
			</content>
			<Footer></Footer>
		</template>

		<div id="updates-available-container" style="position: fixed; left: 0; top: 0; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, 0.8);" v-if="updateExists">
			<div id="updates-available-confirmation-box">
				<p class="text-center">
					E' disponibile una nuova versione.
				</p>

				<br>

				<button type="button" id="updates-available-confirmation-button" @click="refreshApp()">
					Aggiorna
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import update from '@/mixins/update'

export default {
	name: "Application",
	components: {
		Header, Footer,
	},
	mixins: [update],
	computed: {
		layout () {
			return this.$route.name == 'Home' ? 'content-and-footer' : 'header-content-footer'
		}
	}
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
table {
	border-collapse: collapse;
}
#app {
	display: grid;
	height: 100vh;
	color: white;
	background-color: #948168;
	font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-tap-highlight-color: transparent;
}
#app[layout=content-and-footer] {
	grid-template-areas: "content" "footer";
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
}
#app[layout=header-content-footer] {
	grid-template-areas: "header" "content" "footer";
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
}
#app content {
	overflow-y: scroll;
}
.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-responsive {
	max-width: 100%;
	height: auto;
	display: block;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
#updates-available-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
#updates-available-confirmation-button {
	background-color: #CCC;
	border-radius: 16px;
	padding: 8px;
	width: 100%;
}
#updates-available-confirmation-button:hover {
	background-color: #BBB;
}
</style>