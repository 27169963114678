import Vue from 'vue'
import App from './Application.vue'
import VueLazyload from 'vue-lazyload'

import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false
Vue.use(VueLazyload, {
	observer: true,
	loading: require('./assets/Essenza Cofani - Placeholder.png')
})

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')